import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { StageLogo } from '@stageplus/components/src/stage-logo';
import LogoutIcon from '@stageplus/icons/react/logout';
import MenuIcon from '@stageplus/icons/react/menu';
import MenuCloseIcon from '@stageplus/icons/react/menu-close';
import SettingsIcon from '@stageplus/icons/react/settings';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LoginLink, SignupLink } from 'src/components/auth-link';
import AuthenticatedOnly from 'src/components/authenticated-only';
import { ButtonIconOnly } from 'src/components/buttons/icon-button';
import {
  AudioIcon,
  DiscoverIcon,
  LiveIcon,
  NavigationIcon,
  ProfileIcon,
  SearchIcon,
  TicketIcon,
} from 'src/components/navigation/navigation-icons';
import NavigationItem from 'src/components/navigation/navigation-item';
import NavigationItemSecondary from 'src/components/navigation/navigation-item-secondary';
import NavigationLanguageSwitcher from 'src/components/navigation/navigation-language-switcher';
import NavigationLink from 'src/components/navigation/navigation-link';
import NavigationMyStageMenu from 'src/components/navigation/navigation-mystage-menu';
import UnauthenticatedOnly from 'src/components/unauthenticated-only';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useLogout from 'src/hooks/use-logout';
import useTicketStatus from 'src/hooks/use-ticket-status';
import useTranslate from 'src/hooks/use-translate';
import { useIsAuthenticated } from 'src/state/auth';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';
import { TranslationKeyCommon } from 'src/types';
import { getHelpCenterUrl } from 'src/utilities/help-center-helpers';
import { getAppStoreData } from 'src/utilities/mobile-apps-helpers';

/**
 * Main navigation links in the order they should be displayed: Discover, Audio, Live, Search, Tickets
 * This order is the same cross-platform on iOS and tvOS (except for Tickets which is web only)
 */
const links: {
  titleKey: TranslationKeyCommon;
  href: string;
  icon: NavigationIcon;
}[] = [
  { titleKey: 'navigation__discover', href: '/discover', icon: DiscoverIcon },
  { titleKey: 'navigation__audio', href: '/audio', icon: AudioIcon },
  { titleKey: 'navigation__live', href: '/live', icon: LiveIcon },
  { titleKey: 'navigation__search', href: '/search', icon: SearchIcon },
  { titleKey: 'navigation__tickets', href: '/tickets', icon: TicketIcon },
];

/**
 * Main Site Navigation
 */
export function NavigationFull() {
  /** Whether mobile/tablet nav is open */
  const [navOpen, setNavOpen] = useState<boolean>(false);
  /** Top position of mobile/tablet nav relative to window */
  const [mobileNavOffset, setMobileNavOffset] = useState<number>(0);
  const t = useTranslate();
  const logout = useLogout();
  const { isValid: userHasValidSubscription } = useTicketStatus();
  const ref = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const currentLocale = useCurrentLocale();
  const isLoggedIn = useIsAuthenticated();
  const isLandingPage = router.pathname === '/';

  // If the user is logged in, clicking the logo should navigate to the discover page instead of the landing page
  const handleLogoClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      if (isLoggedIn) {
        event.preventDefault();
        void router.push('/discover');
      }
    },
    [isLoggedIn, router],
  );

  // handle mobile nav open
  function handleNavOpen() {
    // try to adjust the mobile navigation position first
    if (!ref.current) return;
    // get the top position of the navigation container relative to the window
    const { top, height } = ref.current.getBoundingClientRect();
    // position the mobile nav just below the navigation container
    setMobileNavOffset(top + height);
    // toggle the navigation appearance
    setNavOpen(true);
  }

  // handle mobile closing of the mobile navigation
  function handleNavClose() {
    setNavOpen(false);
  }

  // handles clicking log out button
  function handleLogoutClick() {
    // make sure the navigation is closed
    setNavOpen(false);
    // log out
    logout();
  }

  // prepare the navigation links for rendering
  const navLinks = useMemo(
    // filter out ticket navigation item if user has a subscription
    () => links.filter((link) => link.href !== '/tickets' || !userHasValidSubscription),
    [userHasValidSubscription],
  );

  /** Secondary links (shown in mobile menu) */
  const secondaryLinks: {
    titleKey: TranslationKeyCommon;
    href: string;
  }[] = [
    // contact page
    { titleKey: 'navigation__contact', href: getHelpCenterUrl(currentLocale) },
    // link to the app store
    { titleKey: 'navigation__get_app', href: getAppStoreData(currentLocale, 'apple').url },
  ];

  return (
    <Fragment>
      <header
        ref={ref}
        className="nav-area flex shrink-0 flex-nowrap items-center justify-center bg-lightBlueNavigation px-5 lg:px-8"
        data-test="navigation"
      >
        <TrackingContext.Provider value={TrackingContextValues.TopNavigation}>
          {/* Left nav content */}
          <div className="flex grow basis-0">
            <div className="mr-auto">
              {/* Logo - Small size on smaller breakpoints, larger on >= lg */}
              <Link
                href="/"
                onClick={handleLogoClick}
                data-test="navigation-logo"
                className="block rounded-sm outline-offset-2 focus-visible:focus-outline"
              >
                <StageLogo alt={t('logo__title')} className="h-5 lg:h-8" />
              </Link>
            </div>
          </div>

          {/* Main nav - Visible on breakpoints >= xl */}
          <nav className="hidden h-full xl:block" aria-label={t('navigation__landmark_main_title')}>
            <ul className="grid h-full auto-cols-fr grid-flow-col content-center">
              {navLinks.map((item) => (
                <li key={item.href}>
                  <NavigationLink href={item.href}>
                    <NavigationItem iconComponent={item.icon} underline>
                      {t(item.titleKey)}
                    </NavigationItem>
                  </NavigationLink>
                </li>
              ))}
            </ul>
          </nav>

          {/* Right nav content */}
          <div className="flex h-full basis-0 items-center justify-end gap-x-2 xl:grow">
            <nav className="flex h-full items-center gap-x-2" aria-label={t('navigation__landmark_secondary_title')}>
              {/* Signup button - Always shown (when not logged in) except on Landing Page (which already contains Signup buttons) */}
              {!isLandingPage && (
                <UnauthenticatedOnly>
                  <SignupLink dataTest="button-user-signup" buttonType="primary-header" trackingTextId="button__signup">
                    {t('button__signup')}
                  </SignupLink>
                </UnauthenticatedOnly>
              )}
              {/* Login link - Shown on breakpoints >= lg (when not logged in) */}
              <UnauthenticatedOnly>
                <LoginLink
                  className="group hidden py-1 outline-none xl:block"
                  dataTest="button-user-login"
                  trackingTextId="navigation__login"
                >
                  <NavigationItem iconComponent={ProfileIcon}>{t('navigation__login')}</NavigationItem>
                </LoginLink>
              </UnauthenticatedOnly>
              {/* MySTAGE+ menu - Shown on breakpoints >= lg (when logged in) */}
              <NavigationMyStageMenu className="hidden h-full xl:block" />
              {/* Nav language dropdown - Always shown */}
              <NavigationLanguageSwitcher />
            </nav>

            {/* Main navigation landmark should be accessible on all screen sizes,
              so we wrap the burger button in <nav> on small screens */}
            <nav className="xl:hidden" aria-label={t('navigation__landmark_main_title')}>
              {/* Nav open/close button - Hidden on breakpoints >= xl */}
              <ButtonIconOnly
                className="-mx-1 rounded-sm px-1 py-3"
                title={t(navOpen ? 'navigation__hide_navigation' : 'navigation__display_navigation')}
                onClick={navOpen ? handleNavClose : handleNavOpen}
                dataTest="navigation-toggler"
                icon={navOpen ? <MenuCloseIcon /> : <MenuIcon />}
              />
            </nav>
          </div>
        </TrackingContext.Provider>
      </header>

      {/* Mobile nav */}
      <Transition show={navOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 flex" style={{ top: mobileNavOffset }} onClose={setNavOpen}>
          <TransitionChild
            as={Fragment}
            enter="transform transition ease-in-out duration-150"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-150"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex flex-1 flex-col overflow-y-scroll bg-mainBgBlueC2 p-10" data-test="navigation-mobile">
              {/* Button to hide the menu that appears on focus, and accessible
                  within the focus trap of the mobile navigation modal */}
              <button
                className="absolute -top-full left-5 rounded-lg bg-surface-0 px-4 py-3 text-buttonForeground outline-offset-0 focus-outline focus:-top-14 md:focus:-top-16"
                onClick={handleNavClose}
              >
                {t('navigation__hide_navigation')}
              </button>
              {/* Main mobile nav */}
              <TrackingContext.Provider value={TrackingContextValues.MobileNavigation}>
                <div className="mb-10 divide-y divide-divider">
                  {navLinks.map((item) => (
                    <NavigationLink
                      key={item.href}
                      href={item.href}
                      onClick={() => setNavOpen(false)}
                      className="block"
                      // hide the tickets link if the user already has a subscription
                      hidden={item.href === '/tickets' && userHasValidSubscription}
                    >
                      <NavigationItem iconComponent={item.icon}>{t(item.titleKey)}</NavigationItem>
                    </NavigationLink>
                  ))}
                  <UnauthenticatedOnly>
                    <LoginLink
                      onClick={handleNavClose}
                      className="group block h-16 outline-none"
                      dataTest="button-user-login"
                      trackingTextId="navigation__login"
                    >
                      <NavigationItem iconComponent={ProfileIcon}>{t('navigation__login')}</NavigationItem>
                    </LoginLink>
                  </UnauthenticatedOnly>
                  <AuthenticatedOnly>
                    <NavigationLink
                      href="/my-stage"
                      onClick={() => setNavOpen(false)}
                      className="block"
                      data-test="navigation-mystage-link"
                    >
                      <NavigationItem iconComponent={ProfileIcon}>{t('navigation__mystage')}</NavigationItem>
                    </NavigationLink>
                  </AuthenticatedOnly>
                </div>

                {/* Secondary mobile nav */}
                <ul>
                  {/* Authenticated links (logout + settings) */}
                  <AuthenticatedOnly>
                    <li>
                      <button
                        onClick={handleLogoutClick}
                        data-test="button-user-logout"
                        className="group block rounded-sm outline-offset-2 focus-visible:focus-outline"
                      >
                        <NavigationItemSecondary icon={LogoutIcon}>{t('navigation__logout')}</NavigationItemSecondary>
                      </button>
                    </li>
                    <li onClickCapture={handleNavClose}>
                      <Link
                        href="/settings"
                        className="group block rounded-sm outline-offset-2 focus-visible:focus-outline"
                      >
                        <NavigationItemSecondary icon={SettingsIcon}>
                          {t('navigation__settings')}
                        </NavigationItemSecondary>
                      </Link>
                    </li>
                  </AuthenticatedOnly>

                  {/* Secondary links */}
                  {secondaryLinks.map((item) => (
                    <Link
                      key={item.href}
                      href={item.href}
                      prefetch={false}
                      className="group block rounded-sm outline-offset-2 focus-visible:focus-outline"
                    >
                      <NavigationItemSecondary>{t(item.titleKey)}</NavigationItemSecondary>
                    </Link>
                  ))}
                </ul>
              </TrackingContext.Provider>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>
    </Fragment>
  );
}

/**
 * Placeholder for Site Navigation for the pages that:
 *  - shouldn’t have any distractions (e.g. payment page)
 *  - have to comply with Apple's rules (e.g. when opening tos or privacy pages on iOS we can't allow premium purchase)
 */
export function NavigationLimited() {
  const t = useTranslate();
  return (
    <Fragment>
      <header
        className="grid h-16 shrink-0 grid-cols-[1fr_auto_1fr] items-center justify-between bg-lightBlueNavigation px-4 lg:h-20"
        data-test="navigation-limited"
      >
        <div></div>
        {/* Logo - Small size on smaller breakpoints, larger on >= lg */}
        <div>
          <StageLogo alt={t('logo__title')} className="h-5 lg:h-8" />
        </div>
        <nav className="ml-auto shrink-0" aria-label={t('navigation__landmark_secondary_title')}>
          {/* Nav language dropdown - Always shown */}
          <NavigationLanguageSwitcher />
        </nav>
      </header>
    </Fragment>
  );
}
