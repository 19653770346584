import Link from 'next/link';
import ContainerGrid from 'src/components/container-grid';
import styles from 'src/components/footer-limited.module.css';
import useTranslate from 'src/hooks/use-translate';

/**
 * A footer that contains a copyright notice and links to the legal pages.
 * It can be used in the cases where the navigation has to be hidden as per
 * platform policy, e.g. Apple App Store.
 */
const FooterLimited = () => {
  const t = useTranslate();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root} data-test="footer-limited">
      {/* Privacy Policy, Terms and conditions, and & Imprint links + copyright text */}
      <div className="my-5 py-0.5 lg:my-10 lg:pb-0.5 lg:pt-0">
        <ContainerGrid>
          <div className="col-span-full pb-6 text-center lg:col-span-6 lg:pb-0 lg:text-left">
            <ul data-test="footer-links" className="space-x-6">
              <li className="inline-block">
                <Link
                  href={'/privacy?hideNav=1'}
                  prefetch={false}
                  className="dg-text-regular-4 rounded-sm outline-offset-2 focus-visible:focus-outline"
                >
                  {t('footer__privacy')}
                </Link>
              </li>
              <li className="inline-block">
                <Link
                  href={'/terms?hideNav=1'}
                  prefetch={false}
                  className="dg-text-regular-4 rounded-sm outline-offset-2 focus-visible:focus-outline"
                >
                  {t('footer__terms')}
                </Link>
              </li>
              <li className="inline-block">
                <Link
                  href={'/imprint?hideNav=1'}
                  prefetch={false}
                  className="dg-text-regular-4 rounded-sm outline-offset-2 focus-visible:focus-outline"
                >
                  {t('footer__imprint')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-full text-center lg:col-span-6 lg:text-right">
            <p className="dg-text-regular-4 opacity-55" suppressHydrationWarning>
              &copy; Deutsche Grammophon, {currentYear}
            </p>
          </div>
        </ContainerGrid>
      </div>
    </footer>
  );
};

export default FooterLimited;
