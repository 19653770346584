import { ReactNode } from 'react';
import type { StagePlusIcon } from '@stageplus/icons/react';

type NavigationItemSecondaryProps = {
  children: ReactNode;
  icon?: StagePlusIcon;
};

export default function NavigationItemSecondary({ children, icon: Icon }: NavigationItemSecondaryProps) {
  return (
    <div className="dg-text-regular-4 flex flex-row items-center py-2 group-hover:text-brandYellowC1">
      {Icon && <Icon className="mr-1" aria-hidden />}
      {children}
    </div>
  );
}
