import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useCouponCode } from 'src/hooks/use-coupon-code';
import { isInAbTest } from 'src/utilities/ab-tests';

const couponCodeQueryKey = 'coupon';

/**
 * A hook that retrieves a coupon code from the query string, stores it in the
 * global state, and removes it from the url. (e.g. `?coupon=30PERCENTOFF`)
 */
export function useCouponCodeFromQuery(): void {
  const { query, isReady, replace, pathname } = useRouter();
  const { setCouponCode } = useCouponCode();

  // Get the coupon code from the query string
  const couponCodeFromQuery = useMemo(() => {
    return isReady && typeof query.coupon === 'string' && query.coupon.length > 0 ? query.coupon : undefined;
  }, [isReady, query]);

  // Remove the coupon code from the query string
  const removeCouponCodeFromQuery = useCallback(async () => {
    delete query[couponCodeQueryKey];
    await replace({ pathname, query });
  }, [pathname, query, replace]);

  const pricingAbTest = isInAbTest('xp2025-01-t1');

  // Set the coupon code in the global state and remove it from the query string
  useEffect(() => {
    async function run(): Promise<void> {
      if (couponCodeFromQuery) {
        try {
          // Verify the coupon code and store it in the global state
          await setCouponCode(couponCodeFromQuery);
        } catch (error) {
          /** @todo Decide whether to continue silently ignoring the invalid coupon code, or show a message to the user. */
          console.error('Error setting coupon code from query:', error);
        }
        // Remove the coupon code from the query string
        await removeCouponCodeFromQuery();
      } else {
        // @todo [2025-03-20] Remove this block when the pricing A/B test is over
        // If user is not using some custom coupon code in the query string,
        // and has no manually-applied code, e.g. there is no coupon code in the local storage
        const hasCouponInLocalStorage = window.localStorage.getItem('coupon.zustand')?.includes('expire');
        // we will apply a special coupon code based on the A/B test variant
        if (pricingAbTest === 'v2' && !hasCouponInLocalStorage) {
          // Add a special coupon code for the A/B test variant 2
          await setCouponCode('WORLDPIANOMONTH25'); // 32% off
        }
        // end of the block to remove
      }
    }
    void run();
  }, [couponCodeFromQuery, removeCouponCodeFromQuery, setCouponCode, pricingAbTest]);
}
