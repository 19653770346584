import { Fragment } from 'react';
import ChevronDownIcon from '@stageplus/icons/react/chevron-down';
import FavoriteOutlineIcon from '@stageplus/icons/react/favorite-outline';
import LogoutIcon from '@stageplus/icons/react/logout';
import SettingsIcon from '@stageplus/icons/react/settings';
import { useRouter } from 'next/router';
import AuthenticatedOnly from 'src/components/authenticated-only';
import ContextMenu from 'src/components/context-menu';
import { ProfileIcon } from 'src/components/navigation/navigation-icons';
import NavigationItem from 'src/components/navigation/navigation-item';
import useLogout from 'src/hooks/use-logout';
import useTranslate from 'src/hooks/use-translate';

type NavigationMyStageMenuProps = {
  className?: string;
};

/**
 * React component that returns a MySTAGE+ button with dropdown
 * Renders for authenticated users only
 */
export default function NavigationMyStageMenu({ className }: NavigationMyStageMenuProps) {
  const t = useTranslate();
  const { pathname } = useRouter();
  const logout = useLogout();
  const isCurrentPage = pathname.startsWith('/my-stage') || pathname.startsWith('/settings');

  return (
    <AuthenticatedOnly>
      <ContextMenu className={className}>
        <ContextMenu.Button as={Fragment}>
          {({ open }) => (
            <button className="group flex h-full items-center outline-none" data-test="mystage-dropdown-button">
              <NavigationItem iconComponent={ProfileIcon} active={isCurrentPage} hover={open} underline>
                <span className="flex items-center">
                  {t('navigation__mystage')}
                  <ChevronDownIcon className="size-4 shrink-0" aria-hidden />
                </span>
              </NavigationItem>
            </button>
          )}
        </ContextMenu.Button>
        <ContextMenu.Items>
          {/* MySTAGE+ link */}
          <ContextMenu.Item
            href="/my-stage"
            icon={FavoriteOutlineIcon}
            data-test="navigation-mystage-menu-item-mystage"
          >
            {t('navigation__mystage')}
          </ContextMenu.Item>
          {/* Settings link */}
          <ContextMenu.Item href="/settings" icon={SettingsIcon}>
            {t('navigation__settings')}
          </ContextMenu.Item>
          {/* Logout button */}
          <ContextMenu.Item onClick={logout} icon={LogoutIcon} data-test="button-user-logout">
            {t('navigation__logout')}
          </ContextMenu.Item>
        </ContextMenu.Items>
      </ContextMenu>
    </AuthenticatedOnly>
  );
}
