import UAParser from 'my-ua-parser';

const matchesMediaQuery = (query: string) => {
  return typeof window !== 'undefined' && window.matchMedia && window.matchMedia(query).matches;
};

/**
 * check if a current device is used by a touch interaction
 *  @returns {boolean}
 */
export const isTouchDevice = () => matchesMediaQuery('(hover: none) and (pointer: coarse)');

/**
 * Check if the current device is in portrait mode, probably a mobile device
 * @returns {boolean}
 */
export const isPortraitOrientation = () => matchesMediaQuery('(orientation: portrait)');

/**
 * Check if the current device is larger than a mobile phone, usually a tablet or desktop
 * @returns {boolean}
 */
export const isTabletOrDesktop = () => matchesMediaQuery('(min-width: 768px)');

/**
 * Check if the page is opened within a in-app web view,
 * which in turn might mean if it will deny a file download, e.g. when saving an ics file
 */
export const isInAppBrowser = ((): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  const parser = new UAParser();
  // the browser name will include the strings of the most popular apps
  const { name } = parser.getBrowser();
  // define the most popular app web views that get reported with the parser library
  return Boolean(name?.match(/instagram|facebook/i));
})();

/**
 * Check if the user agent is a bot
 * @param userAgent - the user agent string
 */
export function isUserAgentBot(userAgent?: string | null): boolean {
  return /bot|googlebot|crawler|spider|robot|crawling|checkly/i.test(userAgent || '');
}
