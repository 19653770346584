import Link from 'next/link';
import useTranslate from 'src/hooks/use-translate';
import { trackEvent } from 'src/tracking';

/**
 * Track navigation events for the meta navigation
 * @param label The label of the clicked link
 */
const metaNavigationTrack = (label: string) => {
  trackEvent({
    eventName: 'Navigation',
    action: 'Meta Nav',
    label,
  });
};

/**
 * A meta navigation to the other Deutsche Grammophon web properties
 * positioned above the main navigation
 * @component
 */
const NavigationMeta = (): JSX.Element => {
  const t = useTranslate();

  return (
    <nav data-test="navigation-meta" className="dg-meta" aria-labelledby="dg-meta">
      <p id="dg-meta">{t('meta_navigation__explore')}</p>
      <ul>
        <li>
          <a href="https://dgt.link/stage-dg-website" onClick={() => metaNavigationTrack('DG Website')}>
            {t('meta_navigation__label')}
          </a>
        </li>
        <li>
          {/* Use Next Link to avoid having several links with the same text (STAGE+),
              but different URLs */}
          <Link href="/" onClick={() => metaNavigationTrack('STAGE+')} className="active" aria-current="true">
            {t('meta_navigation__stage')}
          </Link>
        </li>
        <li>
          <a href="https://dgt.link/stage-dg-store" onClick={() => metaNavigationTrack('DG Shop')}>
            {t('meta_navigation__shop')}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationMeta;
